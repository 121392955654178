.mySwiper{
    margin-top: 90px;

    img{
        max-width: 100%;
    }

    .swiper-button-next, .swiper-button-prev{
        color: #B32929;
    }
}

.servicos{
    width: 100%;
    height: 745px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

h2{
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.services-title{
    margin-top: 50px;
}

.clients, .partners{
    width: 100%;
    height: 300px;

    h2{
        font-size: 46px;
        padding-top: 15px;
    }
}


.clients{
    background-image: url(../../assets/redBanner.png);

    h2{
        color: #ffffff;
        font-size: 50px;
        margin-bottom: 15px;
    }

    .clients-row{
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .client-logo{
        width: auto;
        height: 65px;
    }
}

.section-title{
    padding-top: 15px;
    color: #B32929;
    font-size: 50px !important;
    margin-bottom: 15px;
}

.partners{
    width: 100%;
    height: fit-content;
    background-image: url(../../assets/PartnersBanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .partners-rows{
        width: 100%;
        height: fit-content;
    }

    .partner-logo{
        width: 150px;
        height: auto;
    }

    .upper-partners, .lower-partners{
        width: 100%;
        padding: 25px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    #powermattech{
        width: 250px;
    }
}

.collaborators{
    height: fit-content;

    .colab-cards-holder {
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
        justify-items: center;
        align-items: center;
    }
}

.about{
    min-height: 650px;
    max-height: fit-content;
    padding: 0 100px;
    display: flex;
    overflow: hidden;

    .about-column{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .who{
        h2{
            text-align: left;
        }

        p{
            margin-right: 30px;
            padding: 50px 50px 50px 0;
            font-size: 18px;
            text-align: justify;
        }
    }

    .grid-holder{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .qualities{
            width: fit-content;
            height: fit-content;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-items: center;
            gap: 25px;
        }
    }


}

.contact{
    height: 250px;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
        margin-bottom: 30px;
    }
}

.more-about{
    padding: 50px 100px;

    p{
        font-size: 18px;
    }
}

@media(max-width: 1665px){

.collaborators{
    height: fit-content;

    .colab-cards-holder {
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        justify-items: center;
        align-items: center;
    }
}
}

@media(max-width: 1400px){
    .about{
        height: fit-content;

        .about-column, .grid-holder{
            margin: 40px 0;
        }
    }
}

@media (max-width: 965px){
    .partners{
        background-repeat: repeat;
        background-size: auto;

        .upper-partners, .lower-partners{
            height: fit-content;
            flex-direction: column;

            a{
                margin: 25px 0;
            }
        }

    }
}

@media (max-width: 920px){
    .mySwiper{
        .swiper-button-next::after, .swiper-button-prev::after{
            font-size: 25px;
        }

        .swiper-button-next{
            right: 0;
        }

        .swiper-button-prev{
            left: 0;
        }
    }

    .banner{
        max-width: 100%;
    }

    .about{
        padding: 40px;
        flex-direction: column;

        .about-column{
            width: fit-content;
        }

        .who{
            height: fit-content;

            h2{
                padding-top: 25px;
            }

            p{
                padding: 50px 0;
            }
        }

        .grid-holder{
            width: 100%;

            .qualities{
                padding: 50px 0 0 0;
                gap: 10px;
            }
        }


    }

    .clients{
        height: fit-content;

        .clients-row{
            height: fit-content;
            flex-direction: column;
        }

        .client-logo{
            height: 70px;
            margin: 30px 0;
        }
    }

    .servicos{
        height: 1850px;
        flex-direction: column;
    }

    .contact{
        h2{
            font-size: 28px;
        }
    }

    .more-about{
        padding: 40px;

        h2{
            margin-bottom: 20px;
        }
    }
}

@media(max-width: 850px){

    .collaborators{
        height: fit-content;

        .colab-cards-holder {
            padding: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            justify-items: center;
            align-items: center;
        }
    }
}

@media(max-width: 680px){

    .collaborators{
        height: fit-content;

        .colab-cards-holder {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width: 450px){
    .mySwiper{
        img{
            width: 110%;
        }
    }
}
