.news-card{
    width: 500px;
    height: 390px;
    background-color: white;
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    align-items: center;

    .image-section{
        width: 40%;
        height: 100%;
        position: relative;

        img{
            width: 100%;
            height: 100%;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
        }

        a{
            width: 95%;
            padding: 5px 0 7px 5%;
            font-size: 8px;
            background-color: #F0F0F0;
            color: #B32929;
            opacity: 0.7;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 10;
            border-bottom-left-radius: 15px;
        }
    }

    .text-section{
        width: 60%;
        height: 100%;
        padding: 0 20px;
        position: relative;

        h3{
            margin-top: 20px;
            font-size: 22px;
        }

        p{
            margin-top: 15px;
            font-size: 15px;
            text-align: justify;
        }

        .info-section{
            width: 86%;
            color: #6B6B6B;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: 20px;

            .label{
                padding: 5px;
                background-color: #B32929;
                border-radius: 5px;
                font-weight: 600;
                color: #FFF;
            }
        }
    }
}

@media(max-width: 900px){
    .news-card{
        width: 320px;
        // height: 320px;
        height: fit-content;

        .image-section{
            display: none;
        }

        .text-section , .info-section{
            height: fit-content;
            width: 100% !important;
        }

        h3{
            font-size: 18px !important;
        }

        p{
            margin-top: 20px !important;
            height: fit-content;
            font-size: 8px;
        }

        .info-section{
            margin-top: 20px;
            margin-bottom: 20px;
            position: static !important;
        }

        span{
            font-size: 12px;
        }


    }
}
