h1{
    width: 100%;
    margin-top: 90px;
    padding: 50px 0;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}

.text-holder{
    width: 100%;
    padding: 0 100px;
    margin-bottom: 150px;

    ul li:last-child{
        margin-bottom: 50px;
    }

    li{
        max-width: calc(100vw - 200px);
        padding-top: 15px;
        margin-left: 20px;
        font-size: 16px;
    }
}

h2.micro-titles{
    text-align: left;
    font-size: 28px;
}

@media(max-width: 800px){
    h1{
        font-size: 34px;
    }

    .text-holder{
        max-width: calc(100vw - 80px);
        padding: 0 40px;

        ul{
            width: calc(100vw - 75px);
        }

        ul li:last-child{
            margin-bottom: 70px;
        }

        li{
            width: calc(100vw - 90px);
            max-width: 100%;
            padding-top: 30px;
            margin-left: 15px;
            text-align: justify;
        }
    }

    h2.micro-titles{
        max-width: 320px;
        font-size: 24px;
    }
}
