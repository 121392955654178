@keyframes left-to-right{
    0% {
        transform: translateX(-80vw);
    }
}

@keyframes right-to-left {
    100% {
        transform: translateX(-80vw);
    }
}

.burger-btn{
    width: 40px;
    height: 34.5px;
    padding: 7px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 25px;
    z-index: 1000;

    .line{
        width: 100%;
        height: 2.5px;
        background-color: #000;
        border: none;
        transition: transform 0.4s ease-in-out;
    }

    .first.line{
        transform-origin: 0% 0%;
    }

    .last.line{
        transform-origin: 0% 100%;
    }

    .first.line.X{
        transform: rotate(45deg);
    }

    .second.line.X{
        transform: scaleY(0);
    }

    .last.line.X{
        transform: rotate(-45deg);
    }
}

.side-bar{
    width: 50vw;
    min-height: 100vh;
    overflow: hidden;
    padding: 40px 50px;
    background-color: #ffffff;
    box-shadow: 0px 3px 30px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    border-top: 5px solid #B32929;
    z-index: 1200;

    .side-bar-link{
        width: 100%;
        padding: 20px 0;
        font-size: 22px;
        text-decoration: none;
        color: #000000;
    }
}

.open{
    animation: left-to-right 1s ease;
}

.closed{
    animation: right-to-left 1s ease forwards;
}

@media (min-width: 768px){
    .burger-btn, .side-bar{
        display: none;
    }
}
