.colab-card{
    width: 250px;
    height: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    border-top: 4px solid #B32929;

    img{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        position: absolute;
        top: 30px;
        left: 75px;
    }

    h4{
        width: 100%;
        position: absolute;
        top: 160px;
        text-align: center;
        font-size: 18px;
    }

    a{
        width: 95px;
        padding: 10px 20px;
        background-color: #0A79B5;
        border-radius: 20px;
        text-decoration: none;
        font-weight: 600;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 210px;
        left: 57px;

        .icon{
            font-size: 20px;
        }
    }
}
