.desktop-menu{
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a{
        text-decoration: none;
        color: #000000;
    }
}

@media (max-width: 768px){
    .desktop-menu{
        display: none;
    }
}
