.rectangle{
    width: 320px;
    height: 200px;
    background-color: #F0F0F0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3{
        padding: 0 35px;
        font-size: 28px;
        color: #B32929;
        text-align: center;

    }
}

@media (max-width: 768px){
    .rectangle{
        width: 38vw;
        height: 15vh;

        h3{
            font-size: 14px;
        }
    }
}
