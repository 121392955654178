.news-slider{
    h2{
        margin-top: 50px;
    }

    .mySwiper{
        margin-top: 20px;
        padding: 50px 0;

        .swiper-slide{
            display: flex;
            align-items: center;
            justify-content: center;
        }


    }
}
