@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600;800&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

button{
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #B32929;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
