.contact-form{
    height: 1000px;
    margin-top: 90px;
    padding: 0 15vw;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        padding-top: 20px;
    }

    input, textarea{
        width: 100%;
        margin-top: 40px;
        padding: 18px;
        border: 1px solid #C3C3C3;
        border-radius: 10px;
        font-size: 18px;
    }

    textarea{
        height: 300px;
        justify-items: end;
    }

    button{
        width: 250px;
        margin-top: 40px;
    }
}

@media (max-width: 768px){
    .contact-form{
        height: fit-content;
        padding-bottom: 50px;

        h2{
            font-size: 35px;
        }
    }
}