.card{
    width: 380px;
    height: 540px;
    margin: 25px 0;
    background: #FFFFFF;
    border-top: 4px solid #B32929;
    box-shadow: 0px 5px 19px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .icon{
        font-size: 120px;
        margin: 30px 0;
    }

    h3{
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 30px;
    }

    p{
        height: 300px;
        padding: 0 18px;
        font-size: 18px;
        text-align: justify;
    }
}

@media (max-width: 768px){
    .card{
        width: 85vw;
        height: 550px;

        .icon{
            margin: 35px 0;
        }

        h3{
            margin-bottom: 35px;
        }

        p{
            height: fit-content;
            padding: 0 18px 18px 18px;
            font-size: 18px;
            text-align: justify;
        }
    }
}
