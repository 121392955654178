.footer{
    width: 100vw;
    height: 350px;
    background-image: url('../../assets/footerbg.png');
    color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .infos{
        width: 400px;
        position: absolute;
        left: 100px;
        top: 25px;

        h2{
            text-align: left;
            padding-top: 25px;
        }

        p{
            padding-top: 15px;
        }
    }

    .social-media{
        width: 300px;
        position: absolute;
        top: 50px;
        right: 100px;

        h2{
            text-align: left;
        }

        .icon{
            width: 50px;
            height: 50px;
            padding-right: 20px;
        }
    }

    .rights{
        padding: 20px;
        font-size: 18px;
        text-align: center;
        background: transparent;
        position: absolute;
        bottom: 0;
    }

    a{
        text-decoration: none;
        color: #FFFFFF;
    }
}

@media (max-width: 768px){
    .footer{
        width: auto;
        height: 450px;
        padding: 0;
        position: inherit;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        h2{
            font-size: 28px;
        }

        .infos{
            width: 80%;
            position: inherit;
        }

        .social-media{
            width: 80%;
            position: inherit;

            h2{
                padding-top: 25px;
            }
        }

        .rights{
            max-width: 250px;
            padding: 18px 0 0 0;
            position: inherit;
            font-size: 16px;
            // overflow-wrap: anywhere;
            // line-break: anywhere;
        }

    }
}
