.header{
    width: 100%;
    height: 90px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 3px 30px 3px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    z-index: 10;

    img{
        width: 220px;
        height: 61px;
        position: absolute;
        left: 50px;
    }

    .desktop-menu{
        position: absolute;
        right: 50px;
    }
}

@media (max-width: 768px){
    .header{
        img{
            left: 25px;
        }
    }
}